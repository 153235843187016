<template>
  <v-container fluid class="pa-6">
    <v-card class="text-center">
      <v-card-title>
        <v-spacer />
        <span v-text="$t('multitask.welcome.title')" />
        <v-spacer />
      </v-card-title>

      <v-card-text>
        <v-row align="center">
          <v-col cols="12" md="6">
            <p
              class="text-h5 justify-last-center"
              v-text="$t(`multitask.welcome.${gameName}.explain`)"
            />
          </v-col>
          <v-col cols="12" md="6">
            <video width="100%" autoplay controls>
              <source
                :src="$t(`multitask.welcome.${gameName}.videoInstructionUrl`)"
                type="video/mp4"
              >
            </video>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <ag-confirm-dialog
          :title="$t('general.confirmation')"
          :message="$t('multitask.welcome.confirmMessage')"
          :cancel-label="$t('general.cancel')"
          :confirm-label="$t('general.start')"
          validation-btn-color="accent"
          @confirm="startGame"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingStartGame"
              :disabled="loadingStartGame"
              color="accent"
              v-bind="attrs"
              v-on="on"
              v-text="$t('multitask.welcome.startGame')"
            />
          </template>
        </ag-confirm-dialog>

        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'

import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'WelcomeContainer',
  components: {
    AgConfirmDialog,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingStartGame: false,
  }),
  computed: {
    ...mapGetters('session', {
      gameNameListByResourceId: 'gameNameListByResourceId',
    }),
    gameName () {
      return this.gameNameListByResourceId[this.game.id]
    },
  },
  methods: {
    async startGame () {
      this.loadingStartGame = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.startGame)
      this.loadingStartGame = false
    },
  },
}
</script>
